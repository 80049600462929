header {
    position: fixed;
    top: -250px;
    left: 0;
    width: 100%;
    z-index: 9;
    transition: all 0.3s;
    .mobile-menu {
        display: none;
    }
    .bg-white{
       background-color: white; 
    }
    &.show {
        top: 0;
        .menu-section {
            &:after {
                height: 100px;
            }
            .single-menu {
                &.has-child {
                    // &:hover {
                    //     .child-menu {
                    //         top: 80px;
                    //     }
                    // }
                    &.showing {
                        .child-menu {
                            top: 80px;
                        }
                    }
                }
            }
        }
        .child-menu {
            &:hover {
                // top: 120px;
            }
        }
        @media screen and (min-width: 992px) {
            &.on-top {
                .logo {
                    max-height: 100px;
                    img {
                        opacity: 1;
                    }
                }
                .weather{
                    display: flex;
                }
                .menu-section {
                    .single-menu {
                        &.has-child {
                            // &:hover {
                            //     .child-menu {
                            //         top: 120px;
                            //     }
                            // }
                            &.showing {
                                .child-menu {
                                    top: 120px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .logo {
        position: relative;
        width: 100%;
        padding: 10px 15px;
        text-align: center;
        background-color: white;
        .logo-img {
            max-width: 140px;
            margin: 0 auto;
            @media screen and (max-width: 575px) {
                max-width: 120px;
                padding: 5px 0;
            }
        }
       
        .weather{
            display: flex;
            position: absolute;
            right: 0;
            align-items: center;
            top: 50%;
            transform: translateY(-50%);
            .icon{
              width: 60px;
              height: 60px;
            }
            .condition{
                text-align: left;
                span{
                    line-height: 1.5;
                    font-size: 14px;
                    display: block;
                    &:last-child{
                        font-size: 12px;
                    }
                }
            }
            @media screen and (max-width: 575px) {
                .icon{
                    width: 45px;
                    height: 45px;
                  }
                  .condition{
                    text-align: left;
                    span{
                        line-height: 1.4;
                        font-size: 12px;
                        &:first-child{
                            display: none;
                        }
                        &:last-child{
                            font-size: 10px;
                        }
                    }
                }
            }
        }

        @media screen and (min-width: 992px) {
            max-height: 0;
            overflow: hidden;
            transition: all 0.3s linear;
            .weather{
                display: none;
            }
            img {
                transition: all 0.2s;
                opacity: 0;
            }
        }
    }
    .child-menu {
        position: fixed;
        width: 100%;
        background: var(--blue);
        // background-color: #60c1e4;
        left: 0;
        top: -425px;
        z-index: -1;
        min-height: 200px;
        padding-top: 100px;
        padding-bottom: 35px;
        color: white;
        display: flex;
        align-items: flex-start;
        transition: 0.3s ease-out;
        .hover-area {
            height: 200px;
            position: fixed;
            width: 100%;
            height: 200px;
            top: 120px;
            // background-color: red;
            z-index: 10;
        }
        .child-menu-container {
            display: flex;
            flex-wrap: wrap;
            padding: 20px 0 10px 0;
            a {
                flex: 0 0 33.33%;
                position: relative;
                z-index: 3;
                display: block;
                margin-bottom: 20px;
                &:hover {
                    color: var(--yellow);
                }
            }
        }
        .top-wave {
            position: absolute;
            z-index: 1;
            height: 100%;
            width: 100%;
            top: 0;
            overflow: hidden;
            transition: height 0.3s linear;
            &:before {
                content: "";
                box-sizing: border-box;
                position: absolute;
                bottom: 0px;
                left: 50%;
                height: 0px;
                width: 120%;
                transform: translate(-50%, 50%);
                box-shadow: 0px 0px 0px 300px #60c1e4;
                border-radius: 50%;
                transition: all 0.3s;
                z-index: -1;
                transition: all 0.7s cubic-bezier(0.6, -0.98, 0.35, 1.87);
            }
        }
        &.animated {
            .top-wave {
                height: 150px;
                &:before {
                    height: 180px;
                }
            }
        }
    }
    .menu-section {
        // background-color: white;
        width: 100%;
        // height: 150px;
        position: relative;
        overflow: hidden;
        text-align: center;
        // color: white;
        position: relative;
        // padding-bottom: 60px;
        .menus {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: "Poppins";
            font-size: 20px;

            .link-active {
                color: var(--yellow);
            }
            .single-menu {
                width: 135px;
                margin: 0 20px;
                font-weight: 600;
                color: var(--blue);
                padding-bottom: 75px;
                // margin-bottom: 75px;
                position: relative;
                // cursor: pointer;
                &.link-active {
                    color: var(--yellow);
                }
                .button-bubble {
                    margin-bottom: 0.9em;
                }
                .menu-name {
                    transition: all 0.35s ease;
                    padding-bottom: 15px;
                    &:hover {
                        color: var(--yellow);
                    }
                }
                &.rotate {
                    transform: rotateZ(3deg);
                }
                &:nth-child(1) {
                    top: 25px;
                }

                &:nth-child(2) {
                    top: 10px;
                }

                &:nth-child(4) {
                    top: 10px;
                }

                &:nth-child(5) {
                    top: 25px;
                }
                &.has-child {
                    .menu-name {
                        position: relative;
                        cursor: pointer;
                        &:after {
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            font-family: "Font Awesome 6 Pro";
                            color: var(--yellow);
                            font-weight: 900;
                            content: "\f0d7";
                        }
                    }
                }
            }
        }
        &:after {
            content: "";
            box-sizing: border-box;
            position: absolute;
            bottom: 0px;
            left: 50%;
            height: 50px;
            width: 110%;
            transform: translate(-50%, 50%);
            box-shadow: 0px 0px 0px 200px #fff;
            border-radius: 50%;
            transition: all 0.3s;
            z-index: -1;
            transition: all 1s cubic-bezier(0.6, -0.98, 0.35, 1.87);
        }
    }
    @media (max-width: 991px) {
        .menu-section {
            height: 50px;
            .menus {
                display: none;
            }
        }
        .mobile-menu {
            display: block;

            .menus,
            .submenu {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: white;
                padding: 15px;
                pointer-events: none;
                z-index: -9;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: all 0s;
                transition-delay: 0.3s;
                .single-menu {
                    font-family: Poppins;
                    font-weight: bold;
                    font-size: 32px;
                    margin-bottom: 20px;
                    opacity: 0;
                    transition: all 0.3s;
                    transform: scale(0.5);
                    transform-origin: center;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    @media (max-width: 767px) {
                        font-size: 25px;
                        &.active{
                            color: var(--yellow);
                        }
                    }
                    &.back-button {
                        font-weight: 900;
                        font-size: 20px;
                        position: absolute;
                        top: 20px;
                        text-transform: uppercase;
                        font-family: Poppins;
                        i {
                            margin-right: 5px;
                        }
                    }
                }
            }
            .submenu {
                .single-menu {
                    color: var(--blue);
                    font-size: 28px;
                    margin-bottom: 15px;
                    @media (max-width: 767px) {
                        font-size: 20px;
                        margin-bottom: 10px;
                        &.active{
                            color: var(--orange);
                        }
                    }
                }
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &.open {
                .menus {
                    pointer-events: inherit;
                    z-index: 6;
                    transition-delay: 0s;
                    .single-menu {
                        opacity: 1;
                        transform: scale(1);
                        @for $var from 1 to 10 {
                            &:nth-child(#{$var}) {
                                transition-delay: #{0.3 + $var * 0.2}s;
                            }
                        }
                    }
                }
                .submenu {
                    .parent__menu{
                        margin-bottom: 1em;
                        background-color: var(--orange);
                        padding: .5em;
                        border-radius: .75em;
                    }
                    &.open {
                        pointer-events: inherit;
                        z-index: 8;
                        transition-delay: 0.5s;
                        overflow-y: auto;
                        max-height: 60%;
                        justify-content: start;
                        width: 90%;
                        height: auto;
                        .single-menu {
                            opacity: 1;
                            transform: scale(1);
                            // transition-delay: 0.3s;
                        }
                    }
                }
                .bubble {
                    transition-delay: 0s;
                    &.menu-bubble {
                        transform: translateX(-50%) scale(20);
                    }
                    &.submenu-bubble {
                        &.open {
                            z-index: 7;
                            transform: translateX(-50%) scale(20);
                        }
                    }
                    &.top-bubble {
                        transition-delay: 0.3s;
                    }
                }
                .menu-button {
                    div {
                        &:nth-child(1) {
                            transform: translateY(11px) rotateZ(-45deg);
                        }
                        &:nth-child(2) {
                            opacity: 0;
                        }
                        &:nth-child(3) {
                            transform: translateY(-11px) rotateZ(45deg);
                        }
                    }
                }
            }
            &.subopen {
                .menu-button {
                    div {
                        &:nth-child(1) {
                            transform: translateY(5px) translateX(-10px) rotateZ(-45deg) scaleX(0.55);
                        }
                        &:nth-child(2) {
                            opacity: 1;
                            transform: translateX(1px) scaleX(0.9);
                            transform-origin: left;
                        }
                        &:nth-child(3) {
                            transform: translateY(-5px) translateX(-10px) rotateZ(45deg) scaleX(0.55);
                        }
                    }
                }
            }
            .bubble {
                background-color: #60c1e4;
                height: 110px;
                width: 110px;
                border-radius: 50%;
                position: fixed;
                bottom: -60px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 5;
                transition: 0.5s;
                transition-delay: 0.3s;
                overflow: hidden;
                &.top-bubble {
                    z-index: 5;
                    background-color: var(--blue);
                    transition-delay: 0s;
                }
                &.submenu-bubble {
                    z-index: 4;
                    background-color: var(--yellow);
                }
            }
            .menu-button {
                height: 120px;
                width: 120px;
                border-radius: 50%;
                background-color: var(--blue);
                position: fixed;
                bottom: -60px;
                left: 50%;
                display: flex;
                flex-direction: column;
                padding-top: 20px;
                align-items: center;
                transform: translateX(-50%);
                z-index: 9;
                div {
                    height: 4px;
                    width: 35px;
                    background-color: white;
                    margin-bottom: 7px;
                    transition: all 0.3s linear;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@keyframes header-wave {
    0% {
        height: 30px;
    }
    50% {
        height: 110px;
    }
    100% {
        height: 100px;
    }
}
